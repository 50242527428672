.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.input-group {
  margin-bottom: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom styles for labels */
.fancy-label {
  display: inline-block;
  font-size: 16px;
  color: #3498db;
  margin-right: 10px;
  width: 120px; /* Adjust this width to control the label indentation */
  text-align: right;
}

/* Custom styles for inputs */
.fancy-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #3498db;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: border-color 0.3s ease;
}

.fancy-input:focus {
  outline: none;
  border-color: #2980b9;
}

/* Custom styles for the "Set Default" button */
.fancy-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #3498db;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.fancy-button:hover {
  background-color: #2980b9;
}

.fancy-button:focus {
  outline: none;
}